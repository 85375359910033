<template>
  <div
    v-show="activitiesThrottled.length > 0"
    v-element-visibility="onVisibilityChange"
    class="lg:overflow-y-visible overflow-y-hidden overflow-x-auto lg:overflow-x-visible relative"
  >
    <section class="text-left text-xs whitespace-nowrap w-full mb-4 animate-fade-in">
      <ListHeader
        class="lg:top-16 z-10 flex border-b-[1px] border-b-slate-500 text-xs sm:text-sm"
        :class="{ 'lg:sticky': !scrollDisabled }"
      >
        <h3 class="py-3 pl-2 sm:pl-4 w-4/12">
          Game
        </h3>
        <h3 class="py-3 w-3/12">
          User
        </h3>
        <h3 class="py-3 w-2/12">
          <span class="hidden sm:inline-block sm:w-16 md:w-20 lg:w-32">Action</span>
          <span class="inline-block">Amount</span>
        </h3>
        <h3 class="py-3 pr-2 sm:pr-4 w-3/12 text-right">
          Time
        </h3>
      </ListHeader>

      <div class="relative z-0">
        <article
          v-for="(item, index) in activitiesThrottled"
          :key="`${item.id}-${item.keyId}`"
          class="border-b border-slate-500 py-1 lg:py-1 hover:bg-slate-700 flex items-center flex-wrap max-h-[200px] transition-all duration-200 relative animate-slide-down-fade-in01s"
          :class="index % 2 !== 0 ? 'bg-slate-900/55' : 'bg-slate-900/25'"
          @click.prevent.stop="openGame(item)"
        >
          <div class="game-name py-1 pl-1 sm:pl-2 w-4/12">
            <button class="flex items-center group w-full">
              <figure class="mr-0.5 sm:mr-1 lg:mr-3 rounded sm:rounded-md lg:rounded-xl relative overflow-hidden w-6 h-6 sm:w-8 sm:h-8 lg:h-12 lg:w-12 flex-none border sm:border-2 border-transparent transition-all group-hover:border-gray-300">
                <img
                  class="block relative z-10 w-full h-full object-cover rounded sm:rounded-md lg:rounded-xl"
                  :src="item.data?.imageUrl"
                  width="48"
                  height="48"
                  loading="lazy"
                  decoding="async"
                >
                <Spinner v-if="item.isOpening" class="absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 !py-0 w-6 h-6"/>
              </figure>

              <div class="mb-0 overflow-hidden">
                <h2 class="font-bold whitespace-nowrap text-ellipsis overflow-hidden group-hover:text-gray-300 transition-colors text-xs sm:text-sm">{{ item.data?.name }}</h2>
              </div>
            </button>
          </div>

          <div class="user py-1 w-3/12 sm:inline-block">
            <div class="overflow-hidden">
              <div class="flex items-center group mr-1 py-1">
                <UserAvatar
                  :url="item.avatarUrl"
                  :country="item.country"
                  class="mr-1 border-2 rounded-full border-transparent group-hover:border-gray-300"
                  size="sm"
                  :level="item.level"
                />

                <span class="whitespace-nowrap text-ellipsis overflow-hidden text-xs sm:text-sm">
                  {{ item.displayName }}&nbsp;
                </span>
              </div>
            </div>
          </div>

          <div class="action-amount py-1 w-3/12 flex items-center pr-1">
            <span class="font-bold uppercase text-[9px] 3xs:text-xs sm:text-sm w-8 3xs:w-11 xs:w-14 sm:w-16 md:w-20 lg:w-32 shrink-0" :class="item.actionClass">
              {{ item.actionLabel || item.actionText }}
            </span>

            <CurrencyIconImage
              v-if="item.amount > 0"
              class="inline-block h-[9px] 3xs:h-3 lg:h-4 relative mr-[1px] lg:mr-0.5 ml-0.5 lg:ml-0"
              :currency="item.data.currencyCode"
            />
            <span class="text-xs sm:text-sm">
              <template v-if="item.amount >= 0.0001">
                {{ item.amountRound }}
              </template>
              <template v-else-if="item.amount > 0 && item.amount < 0.0001">
                {{ '< 0.0001' }}
              </template>
              <template v-else>
                FREE
              </template>
            </span>
          </div>

          <div class="time py-1 w-2/12 sm:w-2/12 pr-1 sm:pr-2">
            <div class="overflow-hidden flex items-center justify-end">
              <span class="text-xs text-cyan sm:hidden">{{ item.timestampDisplayTrimmed }}</span>
              <span class="hidden sm:inline-block text-xs sm:text-sm text-cyan">{{ item.timestampDisplay }}</span>
            </div>
          </div>
        </article>
        <InfiniteScrollList
          v-if="!scrollDisabled"
          :items-length="activitiesThrottled?.length"
          @end-viewed="fetchActivityFeed(activitiesThrottled?.length, activityCountMax, true)"
        />
      </div>
    </section>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import uuid from 'uuid';
import { vElementVisibility } from '@vueuse/components';
import { useIntervalFn, refThrottled } from '@vueuse/core';

import { useMinigamesStore } from '@/store/minigames';
import { useWebsocketStore } from '@/store/websocket';
import { useAuthStore } from '@/store/auth';
import { useUserAuth } from '~/composables/useUserAuth';

const props = defineProps({
  scrollDisabled: {
    type: Boolean,
    default: false,
  },
  maxCount: {
    type: Number,
    default: null,
  },
});

const emits = defineEmits([
  'loaded',
]);

const websocketStore = useWebsocketStore();
const { subscribe, unsubscribe, } = websocketStore;

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const minigamesStore = useMinigamesStore();
const { openGameDirect, } = minigamesStore;

const { handleOpenRegisterModal, } = useUserAuth();
const router = useRouter();
const { $api, $rollbar, $round, $dayjs, $trimTimeUnit, } = useNuxtApp();

const activities = ref([]);
const activitiesThrottled = refThrottled(activities, 1000);
const isLoadingData = ref(true);
const activityCountMax = ref(props.maxCount || 20);
const itemToOpen = ref(null);
const takenTimes = ref(1);
const isVisible = ref(false);
const showEntries = ref(false);

useIntervalFn(() => {
  activities.value = activities.value.map((activity) => {
    const timestampDisplay = $dayjs(activity?.timestamp).fromNow();

    return {
      ...activity,
      timestampDisplay: timestampDisplay.startsWith('in ') ? 'now' : timestampDisplay,
      timestampDisplayTrimmed: $trimTimeUnit(timestampDisplay).replace(/ ago$/, ''),
    };
  });
}, 1000);

await init();

async function init() {
  await fetchActivityFeed(0, 50); // TODO: change initial load from 50 to activityCountMax.value when backend is ready (add filter for TYPE in https://api.dev.platform.metawin.com/docs/#/Activity/GetAll)
  emits('loaded');

  subscribe('Activity:Games');
  websocketStore.$onAction(({ name, args, }) => {
    if (document.visibilityState !== 'visible' || !isVisible.value) { return; }

    if (name === 'handleMessage') {
      if (args[0].type === 'Activity' && showEntries.value) {
        useMessageHandler({ name, args, }, activityUpdate, 'Activity', 'GameBet');
      }
      useMessageHandler({ name, args, }, activityUpdate, 'Activity', 'GameWin');
      useMessageHandler({ name, args, }, activityUpdate, 'Activity', 'GameMultiWin');
    }
  });
}

async function fetchActivityFeed(skip, take, append = false) {
  isLoadingData.value = true;
  try {
    const response = await $api('/activity', {
      method: 'GET',
      params: {
        category: 'Games',
        skip,
        take,
      },
    });

    const result = [];
    for (const activity of response) {
      if (showEntries.value || activity.type !== 'GameBet') {
        result.push(...mapActivity(activity));
      }
    }

    if (append) {
      activities.value.push(...result);
      takenTimes.value++;
    } else {
      activities.value = result;
    }
  } catch (err) {
    $rollbar.error('method failed', err);
  } finally {
    isLoadingData.value = false;
  }
}

function activityUpdate(payload) {
  let activitiesLocal = mapActivity(payload);

  activitiesLocal = activitiesLocal.filter(activity => !activities.value.find(a => a.id === activity.id));

  activities.value.unshift(...activitiesLocal);

  const activityCountMaxTotal = activityCountMax.value * takenTimes.value;
  if (activities.value.length > activityCountMaxTotal) {
    activities.value.splice(activityCountMaxTotal);
  }
}

function mapActivity(activity) {
  activity.keyId = uuid();
  activity.timestampDisplay = 'now';
  activity.timestampDisplayTrimmed = 'now';
  activity.isOpening = itemToOpen.value?.id === activity.id;

  switch (activity.type) {
    case 'GameBet':
      activity.actionText = 'ENTRY';
      activity.actionClass = 'text-orange-500';
      activity.actionIconClass = 'icon-ico-arrow-bold-left text-orange-500';
      activity.amount = Number(activity.data.bet);
      activity.amountRound = $round(activity.data.bet, 4);
      break;
    case 'GameWin':
      activity.actionText = 'won';
      activity.actionLabel = 'win';
      activity.actionClass = 'text-green-500';
      activity.actionIconClass = 'icon-ico-arrow-bold-right text-green-500';
      activity.amount = Number(activity.data.win);
      activity.amountRound = $round(activity.data.win, 4);
      break;
  }

  if (activity.type !== 'GameMultiWin') {
    return [activity,];
  }

  return activity.data.winners.map((w, i) => ({
    ...activity,
    id: `${activity.id}-${i}}`,
    actionText: 'won',
    actionLabel: 'win',
    actionClass: 'text-green-500',
    actionIconClass: 'icon-ico-arrow-bold-right text-green-500',
    avatarUrl: w.avatarUrl,
    displayName: w.displayName,
    amount: Number(w.amount),
    amountRound: $round(w.amount, 4),
    data: {
      id: activity.data.id,
      name: activity.data.name,
      imageUrl: activity.data.imageUrl,
      round: activity.data.round,
      type: activity.data.type,
      slug: activity.data.slug,
      win: w.amount,
      currencyCode: w.currencyCode,
      country: w.country,
    },
  }));
}

async function openGame(item) {
  if (!isUserLogged.value) {
    handleOpenRegisterModal();
    return;
  }

  if (item.data.slug && item.data.provider !== 'Arena') {
    router.push(`/games/${item.data.slug}`);
  } else if (item.data.provider === 'Arena' || item.data.minigame === true) {
    itemToOpen.value = item;
    await openGameDirect(item.data, false, 'floating');
    itemToOpen.value = null;
  }
}

function onVisibilityChange(state) {
  isVisible.value = state;
}

onBeforeUnmount(() => {
  unsubscribe('Activity:Games');
});
</script>
